import React from "react";

export const Features = (props) => {
  return (
    // <div id="features" className="text-center">
    //   <div className="container">
    //     <div className="col-md-10 col-md-offset-1 section-title">
    //       <h2>WHAT WE DO?</h2>
    //     </div>
    //     <div className="row">
    //       {props.data
    //         ? props.data.map((d, i) => (
    //             <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
    //               {" "}
    //               <i className={d.icon}></i>
    //               <img src={d.icon} alt="" />
    //               <h3>{d.title}</h3>
    //               <p>{d.text}</p>
    //             </div>
    //           ))
    //         : "Loading..."}
    //     </div>
    //   </div>
    // </div>
    <>
      <div id="features" className="text-center">
        <div className="container">
          {props.data?.map((ele) => {
            return (
              <>
                <div className="col-md-10 col-md-offset-1 section-title">
                  <h2>{ele.title}</h2>
                </div>
                <div className="row" >
                  {ele.data
                    ? ele.data.map((d, i) => (
                      <div
                        key={`${d.title}-${i}`}
                        className="col-xs-6 col-md-3" style={{ height: "400px" }}
                      >
                        {" "}
                        <i className={d.icon}></i>
                        <img src={d.icon} alt="" />
                        <h3>{d.title}</h3>
                        <p>{d.text}</p>
                      </div>
                    ))
                    : "Loading..."}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};
