import React, { useEffect } from "react";
import ReactCompareImage from "react-compare-image";
// import "./style.css";

function Slider(props) {
  useEffect(() => {
    const viewport = document.querySelector("meta[name=viewport]");
    viewport.setAttribute(
      "content",
      "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
    );
    document.body.style.overflowX = "hidden";

    return () => {
      document.body.style.overflowX = "auto";
    };
  }, []);

  return (
    <div id="slider" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Project</h2>
          {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
              dapibus leonec.
            </p> */}
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div key={i} className="col-sm-6 col-md-4 col-lg-4 project-items">
                    <ReactCompareImage
                      sliderLineWidth={4}
                      leftImage={d.before}
                      rightImage={d.after}
                      leftImageLabel="before"
                      rightImageLabel="after"
                      rightImageAlt="after"
                      leftImageCss={{ borderRadius: "10px" }}
                      rightImageCss={{ borderRadius: "10px" }}
                      hover={false}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Slider;
