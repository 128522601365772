import React, { useRef, useEffect } from "react";

export const Navigation = (props) => {
  const navRef = useRef(null);
  const navButtonRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        navRef.current = navButtonRef.current;
        console.log(navRef.current);
      } else {
        navRef.current = null;
      }
    };

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header" style={{ paddingBottom: "43px" }}>
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            ref={navButtonRef}
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <img src="img/logo5.png" alt="logo" width="93px" height="66px" />
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right navbar-main">
            <li>
              <a
                href="#header"
                className="page-scroll"
                onClick={() => navRef.current?.click()}
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#features"
                className="page-scroll"
                onClick={() => navRef.current?.click()}
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#services"
                className="page-scroll"
                onClick={() => navRef.current?.click()}
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="#portfolio"
                className="page-scroll"
                onClick={() => navRef.current?.click()}
              >
                Gallery
              </a>
            </li>
            <li>
              <a
                href="#slider"
                className="page-scroll"
                onClick={() => navRef.current?.click()}
              >
                Projects
              </a>
            </li>
            <li>
              <a
                href="#testimonials"
                className="page-scroll"
                onClick={() => navRef.current?.click()}
              >
                Testimonials
              </a>
            </li>
            <li>
              <a
                href="#team"
                className="page-scroll"
                onClick={() => navRef.current?.click()}
              >
                Team
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className="page-scroll"
                onClick={() => navRef.current?.click()}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
