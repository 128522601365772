import React, { useEffect } from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Services & Expertise</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-md-4">
                {" "}
                <img
                  src={d.icon}
                  alt=""
                  width="250px"
                  height="250px"
                  className="services-img"
                />

                <div className="service-desc">
                  <h3>{d.name}</h3>
                  {/* <p>{d.text}</p> */}
                </div>
              </div>
            ))
            : "loading"}
        </div>
      </div>

    </div>
  );
};
